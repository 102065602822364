import { db } from '@/firebase/config'

const getMyOpps = async (store, uid) => {
  try {
    const temp = []
    let counter = 0;
    await db.collection("Profiles").doc(uid).collection("myOpps").get().then(docs => {
      docs.forEach(doc => {
        temp.push({ ...doc.data(), myOppId: doc.id, counter: counter })
        counter++
      })
      store.value = temp
    })
  } catch (error) {
    alert(error)
  }
};

export default getMyOpps

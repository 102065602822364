import { db } from '@/firebase/config'

export const isInMyOpps = async (userId, oppId) => {
  try {
    let flag = false
    await db.collection("Profiles").doc(userId).collection("myOpps").get().then(snapshot => {
      snapshot.docs.forEach(doc => {
        if (doc.data().oppId == oppId) {
          flag = true
        }
      })
    })
    return flag;
  } catch (error) {
    alert(error)
  }
}

const addMyOpps = async (userId, oppId, status) => {
  try {
    await db.collection("Profiles").doc(userId).collection("myOpps").add({
      oppId: oppId,
      status: status
    })
  } catch (error) {
    alert("addMyOpps" + error)
  }
};

const cutMyOpps = async (userId, oppId) => {
  try {
    await db.collection("Profiles").doc(userId).collection("myOpps").where("oppId", "==", oppId).get().then(docs => {
      let promises = []
      docs.forEach(doc => {
        promises.push(doc.ref.delete())
      })
      return Promise.all(promises)
    })
  } catch (error) {
    alert(error);
  }
}

export const toggleMyOpps = async (userId, oppId, status) => {
  let flag = await isInMyOpps(userId, oppId)
  if (flag) {
    await cutMyOpps(userId, oppId)
  }
  else {
    await addMyOpps(userId, oppId, status)
  }
  // console.log("toggleMyOpps success")
  return
}

export const changeMyOppsStatus = async (userId, oppId, status) => {
  try {
    let docRef = '';
    await db.collection("Profiles").doc(userId).collection("myOpps").where("oppId", "==", oppId).get().then(docs => {
      docs.forEach(doc => {
        docRef = doc.ref;
      })
    })
    await docRef.update({
      status: status
    })
    // console.log("changeMyOppStatus activated");
  } catch (error) {
    alert("changeMyOppStatus " + error);
  }
}
